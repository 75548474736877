import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {Modal, message} from 'ant-design-vue'

Vue.config.productionTip = false;

// 全局引入ant组件中的Modal、message
Vue.use(Modal);
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$message = message;

// 路由变化时的处理
router.beforeEach((to, from, next) => {
    // 销毁所有弹窗
    Modal.destroyAll();

    // 路由发生变化修改页面title
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
});

new Vue({
    router,
    render: h => h(App)
}).$mount("#app");
