// 全局常量定义
const config = {
    server: "https://vc.saikoutech.com/vc-server", // 后台服务根地址
    // server: "http://192.168.50.233:8888", // 后台服务根地址
    success: 0                            // 成功返回码
};

export default {
    config
}
