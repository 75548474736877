<template>
    <div class="h5-page">
        <div class="box">
            <van-icon name="passed" size="120" color="#1989fa"/>
            <p class="tips">提交成功，感谢您的参与</p>
        </div>
    </div>
</template>

<script>
    import {
        Icon
    } from 'vant';

    export default {
        name: "H5DiaoChaSuccess",
        components: {
            [Icon.name]: Icon
        },
        data() {
            return {}
        }
    }
</script>

<style scoped src="@/assets/css/h5.css"></style>
<style scoped src="./index.css"></style>
