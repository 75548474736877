import {Cell, CellGroup, List, NavBar, Search, Tab, Tabs, Tag, Toast} from 'vant';
import GLOBAL from '@/common/global'
import axios from "axios";

export default {
    name: "H5DiaoChaList",
    components: {
        [NavBar.name]: NavBar,
        [Search.name]: Search,
        [List.name]: List,
        [CellGroup.name]: CellGroup,
        [Cell.name]: Cell,
        [Tag.name]: Tag,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab
    },
    data() {
        return {
            active: 0,
            beforeActive: 0,
            tabs: []
        }
    },
    created() {
        // 初始化页面
        this.initPage();
    },
    activated() {
        // 从缓存获取，是否需要刷新页面
        let reload = sessionStorage.getItem("reload-list");
        if (reload) {
            // 如果需要刷新，先清除缓存，再初始化页面
            sessionStorage.removeItem("reload-list");
            this.initPage();
        } else {
            // 获取屏幕缓存位置
            let scrollTop = sessionStorage.getItem("list-scroll-top");
            if (typeof scrollTop !== 'undefined' && scrollTop !== null && scrollTop.length > 0) {
                // 滚动到缓存位置，并清除缓存
                document.querySelector(".van-tabs__content").scrollTop = parseInt(scrollTop);
                sessionStorage.removeItem("list-scroll-top");
            }
        }
    },
    methods: {
        // 初始化页面
        initPage() {
            // 构建tab参数
            let paging = {
                isEmpty: false,
                start: 0,
                currentPage: 0,
                isLoading: false,
                isEnd: false,
                search: '',
                list: []
            };

            this.tabs = [{
                init: true,
                scrollTop: 0,
                title: "未处理",
                status: "0",
                timeDesc: "提交时间",
                paging: JSON.parse(JSON.stringify(paging))
            }, {
                init: true,
                scrollTop: 0,
                title: "已纳入",
                status: "1",
                timeDesc: "纳入时间",
                paging: JSON.parse(JSON.stringify(paging))
            }, {
                init: true,
                title: "已排除",
                status: "2",
                timeDesc: "排除时间",
                paging: JSON.parse(JSON.stringify(paging))
            }];

            // 获取第一页数据
            this.handleList(0);
        },

        // Tab切换前的回调函数
        beforeChangeTab() {
            // 切换前，记录滚动条位置
            let beforeActive = this.active;
            this.beforeActive = beforeActive;
            this.tabs[beforeActive].scrollTop = document.querySelector(".van-tabs__content").scrollTop;
            return true;
        },

        // Tab切换事件
        onChangeTab(name) {
            // 更新当前标签索引
            this.active = name;

            // 如果切换的页面未加载数据，则进行加载
            if (this.tabs[name].init) {
                this.handleList(name);
            } else {
                setTimeout(() => {
                    // 滚动到相应位置
                    document.querySelector(".van-tabs__content").scrollTop = this.tabs[name].scrollTop;
                }, 100);
            }
        },

        // 列表加载
        onLoadList(index) {
            this.handleList(index);
        },

        // 查询列表
        handleList(index) {
            // 修改tab页初始化状态为false
            this.tabs[index].init = false;

            // 设置加载状态为正在加载
            this.tabs[index].paging.isLoading = true;

            // 查询列表
            let start = this.tabs[index].paging.start;
            let status = this.tabs[index].status;
            axios({
                method: "get",
                url: GLOBAL.config.server + "/xnk/diaoCha/list",
                params: {
                    start: start,
                    status: status
                }
            }).then((res) => {
                if (res.data.code === GLOBAL.config.success) {
                    let list = res.data.data;
                    let length = list.length;
                    let currentPage = this.tabs[index].paging.currentPage;

                    if (length > 0) {
                        // 将数据追加到数组，并增加start
                        this.tabs[index].paging.list[currentPage] = list;
                        this.tabs[index].paging.start = start + length;
                        this.tabs[index].paging.isEmpty = false;
                    } else {
                        if (start === 0) {
                            this.tabs[index].paging.isEmpty = true;
                        }
                    }

                    // 设置列表数据是否全部加载
                    if (length < 10) {
                        this.tabs[index].paging.isEnd = true;
                    } else {
                        this.tabs[index].paging.isEnd = false;
                        this.tabs[index].paging.currentPage = currentPage + 1; // 当前页码加1
                    }

                    // 设置本次分页加载完成
                    this.tabs[index].paging.isLoading = false;
                } else {
                    Toast("当前网络不佳，请稍候再试");
                }
            }).catch(() => {
                Toast("当前网络不佳，请稍候再试");
            });
        },

        // 点击列表数据
        onClickListItem(event) {
            // 记录列表位置
            let scrollTop = document.querySelector(".van-tabs__content").scrollTop;
            sessionStorage.setItem("list-scroll-top", scrollTop);

            // 获取id
            let id = event.currentTarget.dataset.id;
            this.$router.push({
                path: '/xnk/diaoCha/h5/info',
                query: {
                    id: id
                }
            });
        }
    }
}
