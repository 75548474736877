import Vue from 'vue'
import Router from 'vue-router'
import H5DiaoChaAdd from '@/views/xnk/diao-cha/h5/add/DiaoChaAdd'
import H5DiaoChaSuccess from '@/views/xnk/diao-cha/h5/success/DiaoChaSuccess'
import H5DiaoChaList from '@/views/xnk/diao-cha/h5/list/DiaoChaList'
import H5DiaoChaInfo from '@/views/xnk/diao-cha/h5/info/DiaoChaInfo'
import DiaoChaList from '@/views/xnk/diao-cha/pc/list/DiaoChaList'
import DiaoChaInfo from '@/views/xnk/diao-cha/pc/info/DiaoChaInfo'

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: '/xnk',
            redirect: '/xnk/diaoCha/list'
        },
        {
            path: '/xnk/diaoCha/list',
            component: DiaoChaList,
            meta: {
                title: '调查列表',
                keepAlive: true // 需要缓存
            }
        },
        {
            path: '/xnk/diaoCha/info',
            component: DiaoChaInfo,
            meta: {
                title: '调查表 - 查看'
            }
        },
        {
            path: '/xnk/diaoCha/h5/add',
            component: H5DiaoChaAdd,
            meta: {
                title: '调查表'
            }
        },
        {
            path: '/xnk/diaoCha/h5/success',
            component: H5DiaoChaSuccess,
            meta: {
                title: '提交成功'
            }
        },
        {
            path: '/xnk/diaoCha/h5/list',
            component: H5DiaoChaList,
            meta: {
                title: '调查列表',
                keepAlive: true // 需要缓存
            }
        },
        {
            path: '/xnk/diaoCha/h5/info',
            component: H5DiaoChaInfo,
            meta: {
                title: '调查表 - 查看'
            }
        }
    ]
})
