import { render, staticRenderFns } from "./DiaoChaAdd.vue?vue&type=template&id=d6987e14&scoped=true&"
import script from "./index.js?vue&type=script&lang=js&"
export * from "./index.js?vue&type=script&lang=js&"
import style0 from "@/assets/css/h5.css?vue&type=style&index=0&id=d6987e14&scoped=true&lang=css&"
import style1 from "./index.css?vue&type=style&index=1&id=d6987e14&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6987e14",
  null
  
)

export default component.exports