import {
    NavBar,
    CellGroup,
    Field,
    Button,
    Row,
    Col,
    Toast,
    Dialog
} from 'vant';
import GLOBAL from '@/common/global'
import axios from "axios";

export default {
    name: "H5DiaoChaInfo",
    components: {
        [NavBar.name]: NavBar,
        [CellGroup.name]: CellGroup,
        [Field.name]: Field,
        [Row.name]: Row,
        [Col.name]: Col,
        [Button.name]: Button,
        [Toast.name]: Toast,
        [Dialog.name]: Dialog
    },
    data() {
        return {
            id: "",
            isInit: true,
            diaoCha: []
        }
    },
    created() {
        // 获取传递过来的信息id
        let id = this.$route.query.id;
        this.id = id;
        axios({
            method: "get",
            url: GLOBAL.config.server + "/xnk/diaoCha/info/" + id
        }).then((res) => {
            if (res.data.code === GLOBAL.config.success) {
                let result = res.data.data;
                this.diaoCha = result;
                if (result.status === '1') {
                    this.diaoCha.statusDesc = "纳入";
                    this.isInit = false;
                } else if (result.status === '2') {
                    this.diaoCha.statusDesc = "排除";
                    this.isInit = false;
                }
            } else {
                Toast("当前网络不佳，请稍候再试");
            }
        }).catch(() => {
            Toast("当前网络不佳，请稍候再试");
        });
    },
    methods: {
        // 返回按钮点击事件
        onPageBack() {
            this.$router.back();
        },

        // 删除按钮点击事件
        onDelete() {
            Dialog.confirm({
                title: '确认删除吗？'
            }).then(() => {
                this.handleDelete();
            });
        },

        // 纳入按钮点击事件
        onClickToFit() {
            let url = GLOBAL.config.server + "/xnk/diaoCha/fit";
            this.handleUpdateStatus(url);
        },

        // 排除按钮点击事件
        onClickToUnFit() {
            let url = GLOBAL.config.server + "/xnk/diaoCha/unFit";
            this.handleUpdateStatus(url);
        },

        // 删除信息
        handleDelete() {
            // 删除url
            let url = GLOBAL.config.server + "/xnk/diaoCha/delete/" + this.id;

            // 请求后台
            axios({
                method: "get",
                url: url
            }).then((res) => {
                if (res.data.code === GLOBAL.config.success) {
                    Toast({
                        message: '删除成功',
                        duration: 1500,
                        onClose: () => {
                            // 操作成功后回到列表页面
                            sessionStorage.setItem("reload-list", true);
                            this.$router.back();
                        },
                    });
                } else {
                    Toast("当前网络不佳，请稍候再试");
                }
            }).catch(() => {
                Toast("当前网络不佳，请稍候再试");
            });
        },

        /**
         * 更新状态
         * @param url 对应url
         */
        handleUpdateStatus(url) {
            // 请求后台，进行状态更新
            axios({
                method: "get",
                url: url + "/" + this.id
            }).then((res) => {
                if (res.data.code === GLOBAL.config.success) {
                    Toast({
                        message: '操作成功',
                        duration: 1500,
                        onClose: () => {
                            // 操作成功后回到列表页面
                            sessionStorage.setItem("reload-list", true);
                            this.$router.back();
                        },
                    });
                } else {
                    Toast("当前网络不佳，请稍候再试");
                }
            }).catch(() => {
                Toast("当前网络不佳，请稍候再试");
            });
        }
    }
}
