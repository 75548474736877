import {
    Layout,
    PageHeader,
    Card,
    Descriptions,
    Divider,
    Button,
    BackTop,
    Modal,
    ConfigProvider
} from 'ant-design-vue'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import axios from 'axios';
import GLOBAL from '@/common/global'

export default {
    name: "DiaoChaInfo",
    components: {
        ALayout: Layout,
        ALayoutHeader: Layout.Header,
        ALayoutContent: Layout.Content,
        ALayoutFooter: Layout.Footer,
        APageHeader: PageHeader,
        ACard: Card,
        ADescriptions: Descriptions,
        ADescriptionsItem: Descriptions.Item,
        ADivider: Divider,
        AButton: Button,
        ABackTop: BackTop,
        AModal: Modal,
        AConfigProvider: ConfigProvider
    },
    data() {
        return {
            locale: zhCN,
            id: "",
            isInit: false,
            loading: false,
            diaoCha: []
        }
    },
    created() {
        // 获取传递过来的信息id
        let id = this.$route.query.id;
        this.id = id;
        axios({
            method: "get",
            url: GLOBAL.config.server + "/xnk/diaoCha/info/" + id
        }).then((res) => {
            if (res.data.code === GLOBAL.config.success) {
                let result = res.data.data;
                this.diaoCha = result;
                if (result.status === '0') {
                    this.isInit = true;
                } else if (result.status === '1') {
                    this.diaoCha.statusDesc = "纳入";
                } else if (result.status === '2') {
                    this.diaoCha.statusDesc = "排除";
                }
            } else {
                this.$message.warning('当前网络不佳，请稍候再试');
            }
        }).catch(() => {
            this.$message.warning('当前网络不佳，请稍候再试');
        });
    },
    methods: {
        // 返回
        onPageBack() {
            this.$router.back();
        },

        // 删除
        onClickDelete() {
            this.loading = true;
            let that = this;
            this.$confirm({
                title: '确认删除吗?',
                okText: '确认',
                cancelText: '取消',
                onOk() {
                    that.handleDelete();
                },
                onCancel() {
                    that.loading = false;
                }
            });
        },

        // 纳入
        onClickToFit() {
            this.loading = true;
            let that = this;
            this.$confirm({
                title: '确认纳入吗?',
                okText: '确认',
                cancelText: '取消',
                onOk() {
                    let url = GLOBAL.config.server + "/xnk/diaoCha/fit";
                    that.handleUpdateStatus(url);
                },
                onCancel() {
                    that.loading = false;
                }
            });
        },

        // 排除
        onClickToUnFit() {
            this.loading = true;
            let that = this;
            this.$confirm({
                title: '确认排除吗?',
                okText: '确认',
                cancelText: '取消',
                onOk() {
                    let url = GLOBAL.config.server + "/xnk/diaoCha/unFit";
                    that.handleUpdateStatus(url);
                },
                onCancel() {
                    that.loading = false;
                }
            });
        },

        // 导出
        onClickDownload() {
            this.loading = true;
            let that = this;
            this.$confirm({
                title: '确认导出吗?',
                content: '如果确认，将以Excel形式导出此调查表',
                okText: '确认',
                cancelText: '取消',
                onOk() {
                    window.location.href = GLOBAL.config.server + "/xnk/diaoCha/export/" + that.id;
                    that.loading = false;
                },
                onCancel() {
                    that.loading = false;
                }
            });
        },

        /**
         * 更新状态
         * @param url 对应url
         */
        handleUpdateStatus(url) {
            // 请求后台，进行状态更新
            axios({
                method: "get",
                url: url + "/" + this.id
            }).then((res) => {
                if (res.data.code === GLOBAL.config.success) {
                    let that = this;
                    this.$message.success(
                        '操作成功',
                        1.5,
                        () => {
                            // 操作成功后回到列表页面
                            sessionStorage.setItem("reload-list", true);
                            that.$router.back();
                        }
                    );
                } else {
                    this.$message.warning('当前网络不佳，请稍候再试');
                }
            }).catch(() => {
                this.$message.warning('当前网络不佳，请稍候再试');
            });
        },

        /**
         * 删除
         */
        handleDelete() {
            // 删除url
            let url = GLOBAL.config.server + "/xnk/diaoCha/delete/" + this.id;

            // 请求后台
            axios({
                method: "get",
                url: url
            }).then((res) => {
                if (res.data.code === GLOBAL.config.success) {
                    let that = this;
                    this.$message.success(
                        '删除成功',
                        1.5,
                        () => {
                            // 操作成功后回到列表页面
                            sessionStorage.setItem("reload-list", true);
                            that.$router.back();
                        }
                    );
                } else {
                    this.$message.warning('当前网络不佳，请稍候再试');
                }
            }).catch(() => {
                this.$message.warning('当前网络不佳，请稍候再试');
            });
        }
    }
}
