import {
    Button,
    Cell,
    CellGroup,
    Col,
    DatetimePicker,
    Field,
    Form,
    NavBar,
    Notify,
    NumberKeyboard,
    Picker,
    Popup,
    Radio,
    RadioGroup,
    Row,
    Toast
} from 'vant';

import axios from 'axios';
import GLOBAL from '@/common/global'

// 定义常量
const YES = '1';
const NO = '0';
const PAO_TUAN_OTHER = '其他';

export default {
    name: "H5DiaoChaAdd",
    components: {
        [NavBar.name]: NavBar,
        [Form.name]: Form,
        [CellGroup.name]: CellGroup,
        [Cell.name]: Cell,
        [Field.name]: Field,
        [Row.name]: Row,
        [Col.name]: Col,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
        [NumberKeyboard.name]: NumberKeyboard,
        [DatetimePicker.name]: DatetimePicker,
        [Picker.name]: Picker,
        [Button.name]: Button,
        [Popup.name]: Popup,
        [Toast.name]: Toast,
        [Notify.Component.name]: Notify.Component
    },
    data() {
        return {
            yes: YES,
            no: NO,
            page: {
                total: 14,  // 总数
                current: 0,  // 当前索引
            },
            // 表单内容
            diaoCha: {
                xingMing: {
                    title: "姓名",
                    value: "",
                    maxlength: 20,
                    rules: [{required: true, message: '请填写姓名'}],
                    pageIndex: 0
                },
                dianHua: {
                    title: "手机号码",
                    maxlength: 11,
                    rules: [
                        {required: true, message: '请填写手机号码'},
                        {pattern: /^1(3[0-9]|4[5,7]|5[0-9]|7[0-9]|8[0-9]|9[1,8-9])\d{8}$/, message: '请填写正确的手机号码'}
                    ],
                    pageIndex: 0
                },
                xingBie: {
                    title: "性别",
                    value: "",
                    maxlength: 1,
                    rules: [{required: true, message: '请选择性别'}],
                    pageIndex: 0
                },
                chuShengNianYue: {
                    title: "出生年月",
                    value: "",
                    maxlength: 1,
                    rules: [{required: true, message: '请选择出生年月'}],
                    pageIndex: 0
                },
                shenGao: {
                    title: "身高",
                    value: "",
                    maxlength: 3,
                    rules: [{required: true, message: '请填写身高'}],
                    pageIndex: 0
                },
                tiZhong: {
                    title: "体重",
                    value: "",
                    maxlength: 3,
                    rules: [{required: true, message: '请填写体重'}],
                    pageIndex: 0
                },
                paoTuan: {
                    title: "所在跑团",
                    value: "",
                    maxlength: 20,
                    rules: [{required: true, message: '请选择所在跑团'}],
                    pageIndex: 1
                },
                paoTuanOther: {
                    show: false,
                    title: "跑团名称",
                    value: "",
                    maxlength: 20,
                    rules: [{required: false, message: '请填写跑团名称'}],
                    pageIndex: 1
                },
                maLing: {
                    title: "马龄",
                    value: "",
                    maxlength: 1,
                    rules: [{required: true, message: '请选择马龄'}],
                    pageIndex: 2
                },
                yuePaoLiang: {
                    title: "月跑量",
                    value: "",
                    maxlength: 1,
                    rules: [{required: true, message: '请选择月跑量'}],
                    pageIndex: 3
                },
                quanMaCiShu: {
                    title: "全马参与次数",
                    value: "",
                    maxlength: 4,
                    rules: [{required: true, message: '请填写全马参与次数'}],
                    pageIndex: 4
                },
                quanMaChengJi: {
                    title: "全马最好成绩",
                    value: "",
                    maxlength: 1,
                    rules: [{required: false, message: '请填写全马最好成绩'}],
                    pageIndex: 4
                },
                banMaCiShu: {
                    title: "半马参与次数",
                    value: "",
                    maxlength: 4,
                    rules: [{required: true, message: '请填写半马参与次数'}],
                    pageIndex: 5
                },
                banMaChengJi: {
                    title: "半马最好成绩",
                    value: "",
                    maxlength: 1,
                    rules: [{required: false, message: '请填写半马最好成绩'}],
                    pageIndex: 5
                },
                xiYan: {
                    title: "吸烟情况",
                    value: "",
                    maxlength: 1,
                    rules: [{required: true, message: '请选择吸烟情况'}],
                    pageIndex: 6
                },
                yinJiu: {
                    title: "喝酒情况",
                    value: "",
                    maxlength: 1,
                    rules: [{required: true, message: '请选择每天酒精量'}],
                    pageIndex: 7
                },
                gaoXueYa: {
                    title: "高血压",
                    value: "",
                    maxlength: 1,
                    rules: [{required: true, message: '请选择是否有高血压'}],
                    pageIndex: 8
                },
                gaoXueYaYaoWuZhiLiao: {
                    title: "高血压药物治疗",
                    value: "",
                    maxlength: 1,
                    rules: [{required: false, message: '请选择是否进行药物治疗'}],
                    pageIndex: 8
                },
                gaoXueYaJiaZuShi: {
                    title: "高血压家族史",
                    value: "",
                    maxlength: 1,
                    rules: [{required: false, message: '请选择是否有高血压家族史'}],
                    pageIndex: 8
                },
                xueYaKongZhi: {
                    title: "血压控制（< 140/90）",
                    value: "",
                    maxlength: 1,
                    rules: [{required: false, message: '请选择是否进行血压控制'}],
                    pageIndex: 8
                },
                guanXinBing: {
                    title: "冠心病",
                    value: "",
                    maxlength: 1,
                    rules: [{required: true, message: '请选择是否有冠心病'}],
                    pageIndex: 9
                },
                guanXinBingYaoWuZhiLiao: {
                    title: "冠心病药物治疗",
                    value: "",
                    maxlength: 1,
                    rules: [{required: false, message: '请选择是否进行药物治疗'}],
                    pageIndex: 9
                },
                guanXinBingJiaZuShi: {
                    title: "冠心病家族史",
                    value: "",
                    maxlength: 1,
                    rules: [{required: false, message: '请选择是否有冠心病家族史'}],
                    pageIndex: 9
                },
                tangNiaoBing: {
                    title: "糖尿病",
                    value: "",
                    maxlength: 1,
                    rules: [{required: true, message: '请选择是否有糖尿病'}],
                    pageIndex: 10
                },
                tangNiaoBingYaoWuZhiLiao: {
                    title: "糖尿病药物治疗",
                    value: "",
                    maxlength: 1,
                    rules: [{required: false, message: '请选择是否进行药物治疗'}],
                    pageIndex: 10
                },
                tangNiaoBingJiaZuShi: {
                    title: "糖尿病家族史",
                    value: "",
                    maxlength: 1,
                    rules: [{required: false, message: '请选择是否有糖尿病家族史'}],
                    pageIndex: 10
                },
                jiaZuCuSi: {
                    title: "家族猝死情况",
                    value: "",
                    maxlength: 1,
                    rules: [{required: true, message: '请选择家族中是否存在猝死情况'}],
                    pageIndex: 11
                },
                jiWangXinDianTu: {
                    title: "既往心电图",
                    value: "",
                    maxlength: 1,
                    rules: [{required: true, message: '请选择既往心电图情况'}],
                    pageIndex: 12
                },
                jiWangXinChao: {
                    title: "既往心脏超声",
                    value: "",
                    maxlength: 1,
                    rules: [{required: true, message: '请选择既往心脏超声情况'}],
                    pageIndex: 13
                },
            },
            // 数字键盘
            numberKeyboard: {
                show: false,
                id: "dianHua",  // 设定默认值，如果不设置，初始化时v-model获取value属性会出错
                title: ""
            },
            // 日期控件
            datePicker: {
                show: false,
                title: '请选择出生年月',
                current: new Date(1980, 0, 1),
                min: new Date(1900, 0, 1),
                max: new Date(2020, 11, 1),
            },
            datePickerFormatter(type, value) {
                let suffix;
                if (type === 'year') {
                    suffix = "年";
                } else if (type === 'month') {
                    suffix = "月";
                } else if (type === 'day') {
                    suffix = "日";
                } else if (type === 'hour') {
                    suffix = "时";
                } else if (type === 'minute') {
                    suffix = "分";
                }
                return value + suffix;
            },
            // 成绩picker
            chengJiPicker: {
                show: false,
                id: "quanMaChengJi",
                title: "请选择全马最好成绩",
                columns: []
            },
            // 跑团picker
            paoTuanPicker: {
                show: false,
                id: "paoTuan",
                title: "请选择所在跑团",
                columns: []
            },
            // 下拉框、radio框等枚举量
            enums: {
                // 是否
                whether: [{
                    value: "0",
                    title: "否"
                }, {
                    value: "1",
                    title: "是"
                }],
                // 性别
                gender: [{
                    value: "0",
                    title: "女"
                }, {
                    value: "1",
                    title: "男"
                }],
                // 马龄
                maLing: [{
                    value: "1",
                    title: "< 1 年"
                }, {
                    value: "2",
                    title: "2 - 3 年"
                }, {
                    value: "3",
                    title: "4 - 6 年"
                }, {
                    value: "4",
                    title: "> 6 年"
                }],
                // 月跑量
                yuePaoLiang: [{
                    value: "1",
                    title: "< 60 km"
                }, {
                    value: "2",
                    title: "60 - 100 km"
                }, {
                    value: "3",
                    title: "100 - 200 km"
                }, {
                    value: "4",
                    title: "200 - 300 km"
                }, {
                    value: "5",
                    title: "300 - 450 km"
                }, {
                    value: "6",
                    title: "> 450 km"
                }],
                // 吸烟
                xiYan: [{
                    value: "1",
                    title: "不吸烟"
                }, {
                    value: "2",
                    title: "每天 10 支以内"
                }, {
                    value: "3",
                    title: "每天 10 - 20 支"
                }, {
                    value: "4",
                    title: "每天 20 支以上"
                }],
                // 喝酒
                yinJiu: {
                    "": [{
                        value: "0",
                        title: "不喝酒"
                    }, {
                        value: "A",
                        title: "每天 12.5g 以内"
                    }, {
                        value: "B",
                        title: "每天 12.5g - 40g"
                    }, {
                        value: "C",
                        title: "每天 40g 以上"
                    }, {
                        value: "1",
                        title: "每天 25g 以内"
                    }, {
                        value: "2",
                        title: "每天 25g - 80g"
                    }, {
                        value: "3",
                        title: "每天 80g 以上"
                    }],
                    "0": [{
                        value: "0",
                        title: "不喝酒"
                    }, {
                        value: "A",
                        title: "每天 12.5g 以内"
                    }, {
                        value: "B",
                        title: "每天 12.5g - 40g"
                    }, {
                        value: "C",
                        title: "每天 40g 以上"
                    }],
                    "1": [{
                        value: "0",
                        title: "不喝酒"
                    }, {
                        value: "1",
                        title: "每天 25g 以内"
                    }, {
                        value: "2",
                        title: "每天 25g - 80g"
                    }, {
                        value: "3",
                        title: "每天 80g 以上"
                    }]
                },
                // 检查
                jianCha: [{
                    value: "1",
                    title: "正常"
                }, {
                    value: "2",
                    title: "异常"
                }, {
                    value: "3",
                    title: "未做过"
                }]
            }
        }
    },
    mounted() {
        // 初始化成绩、跑团选择器中数据
        this.initChengJiPicker();
        this.initPaoTuanPicker();
    },
    methods: {
        // ----------- 初始化方法 ---------------
        // 初始化成绩选择器中数据
        initChengJiPicker() {
            let hours = [],
                minutes = [],
                seconds = [];

            // 构建小时选择数组
            for (let i = 0; i < 24; i++) {
                hours.push(i + '小时');
            }
            for (let j = 0; j < 60; j++) {
                minutes.push(j + '分');
                seconds.push(j + '秒');
            }

            // 构建picker所需参数
            this.chengJiPicker.columns = [{
                values: hours,
                defaultIndex: 4
            }, {
                values: minutes,
                defaultIndex: 0
            }, {
                values: seconds,
                defaultIndex: 0
            }];
        },

        // 初始化跑团选择数据
        initPaoTuanPicker() {
            // 请求后台，获取跑团列表
            axios({
                method: "get",
                url: GLOBAL.config.server + "/xnk/paoTuan/list"
            }).then((res) => {
                if (res.data.code === GLOBAL.config.success) {
                    // 获取跑团数据列表，并在末尾增加”其他“一栏
                    let columns = res.data.data;
                    columns.push(PAO_TUAN_OTHER);
                    this.paoTuanPicker.columns = columns;
                }
            });
        },

        // -------- 各属性值变化事件 ---------------
        // 高血压Radio框变化事件
        onChangeGaoXueYa(value) {
            // 根据是否有高血压，调整子选项验证规则
            if (YES === value) {
                this.diaoCha.gaoXueYaYaoWuZhiLiao.rules[0].required = true;
                this.diaoCha.gaoXueYaJiaZuShi.rules[0].required = true;
                this.diaoCha.xueYaKongZhi.rules[0].required = true;
            } else {
                this.diaoCha.gaoXueYaYaoWuZhiLiao.rules[0].required = false;
                this.diaoCha.gaoXueYaJiaZuShi.rules[0].required = false;
                this.diaoCha.xueYaKongZhi.rules[0].required = false;
            }
        },

        // 冠心病Radio框事件变化
        onChangeGuanXinBing(value) {
            // 根据是否有冠心病，调整子选项验证规则
            if (YES === value) {
                this.diaoCha.guanXinBingYaoWuZhiLiao.rules[0].required = true;
                this.diaoCha.guanXinBingJiaZuShi.rules[0].required = true;
            } else {
                this.diaoCha.guanXinBingYaoWuZhiLiao.rules[0].required = false;
                this.diaoCha.guanXinBingJiaZuShi.rules[0].required = false;
            }
        },

        // 糖尿病Radio框事件变化
        onChangeTangNiaoBing(value) {
            // 根据是否有糖尿病，调整子选项验证规则
            if (YES === value) {
                this.diaoCha.tangNiaoBingYaoWuZhiLiao.rules[0].required = true;
                this.diaoCha.tangNiaoBingJiaZuShi.rules[0].required = true;
            } else {
                this.diaoCha.tangNiaoBingYaoWuZhiLiao.rules[0].required = false;
                this.diaoCha.tangNiaoBingJiaZuShi.rules[0].required = false;
            }
        },

        // ----------- 数字键盘 相关 --------------
        // 数字键盘填写弹窗 - 显示
        onShowNumberKeyboard(e) {
            // 先关闭键盘
            this.numberKeyboard.show = false;

            // 再打开键盘
            setTimeout(() => {
                let id = e.target.id;
                if (typeof (id) !== "undefined" && id !== null && id.length > 0) {
                    let title = this.diaoCha[id].title;
                    let maxlength = this.diaoCha[id].maxlength;
                    this.numberKeyboard.show = true;
                    this.numberKeyboard.id = id;
                    this.numberKeyboard.title = "请填写" + title;
                    this.numberKeyboard.maxlength = maxlength;
                }
            }, 100);
        },

        // 数字键盘填写弹窗 - 关闭
        onCloseNumberKeyboard() {
            this.numberKeyboard.show = false;
        },

        // ----------- 出生年月 相关 --------------
        // 日期控件弹窗 - 显示
        onShowDatePicker() {
            this.datePicker.show = true;
        },

        // 日期控件弹窗 - 关闭
        onCloseDatePicker() {
            this.datePicker.show = false;
        },

        // 日期控件事件 - 确认
        onConfirmDatePicker(value) {
            let date = this.formatDate(value);
            this.datePicker.show = false;
            this.diaoCha.chuShengNianYue.value = date;
        },

        // 格式化时间
        formatDate(date) {
            date = new Date(date);
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();

            if (month < 10) {
                month = "0" + month;
            }
            if (day < 10) {
                day = "0" + day;
            }
            return `${year}-${month}-${day}`;
        },

        // ----------- 选择成绩 相关 --------------
        // 选择成绩控件弹窗 - 显示
        onShowChengJiPicker(e) {
            this.chengJiPicker.show = true;
            let id = e.target.id;
            if (typeof (id) !== "undefined" && id !== null && id.length > 0) {
                let title = this.diaoCha[id].title;
                this.chengJiPicker.id = id;
                this.chengJiPicker.title = "请选择" + title;

                // 根据不同id，设置默认值
                let $picker = this.$refs.chengJiPicker;
                if (id === "quanMaChengJi") {
                    if (typeof $picker === "undefined") {
                        this.chengJiPicker.columns[0].defaultIndex = 4;
                        this.chengJiPicker.columns[1].defaultIndex = 0;
                        this.chengJiPicker.columns[2].defaultIndex = 0;
                    } else {
                        this.$refs.chengJiPicker.setColumnIndex(0, 4);
                        this.$refs.chengJiPicker.setColumnIndex(1, 0);
                        this.$refs.chengJiPicker.setColumnIndex(2, 0);
                    }
                } else {
                    if (typeof $picker === "undefined") {
                        this.chengJiPicker.columns[0].defaultIndex = 2;
                        this.chengJiPicker.columns[1].defaultIndex = 0;
                        this.chengJiPicker.columns[2].defaultIndex = 0;
                    } else {
                        this.$refs.chengJiPicker.setColumnIndex(0, 2);
                        this.$refs.chengJiPicker.setColumnIndex(1, 0);
                        this.$refs.chengJiPicker.setColumnIndex(2, 0);
                    }
                }
            }
        },

        // 选择成绩控件弹窗 - 关闭
        onCloseChengJiPicker() {
            this.chengJiPicker.show = false;
        },

        // 选择成绩控件事件 - 确认
        onConfirmChengJiPicker(value) {
            this.chengJiPicker.show = false;
            let id = this.chengJiPicker.id;
            this.diaoCha[id].value = value.join("");
        },

        // ----------- 选择跑团 相关 --------------
        // 选择成绩控件弹窗 - 显示
        onShowPaoTuanPicker() {
            this.paoTuanPicker.show = true;
        },

        // 选择成绩控件弹窗 - 关闭
        onClosePaoTuanPicker() {
            this.paoTuanPicker.show = false;
        },

        // 选择成绩控件事件 - 确认
        onConfirmPaoTuanPicker(value) {
            this.paoTuanPicker.show = false;
            this.diaoCha.paoTuan.value = value;

            // 判断是否选择”其他“，如果是，则让用户填写跑团名称
            if (PAO_TUAN_OTHER === value) {
                this.diaoCha.paoTuanOther.show = true;
                this.diaoCha.paoTuanOther.rules[0].required = true;
            } else {
                this.diaoCha.paoTuanOther.value = "";
                this.diaoCha.paoTuanOther.show = false;
                this.diaoCha.paoTuanOther.rules[0].required = false;
            }
        },

        // ----------- 底部按钮操作 -------------
        // 上一个
        onPrevious() {
            let current = this.page.current;
            if (current > 0) {
                this.page.current--;
            }
        },

        // 下一个
        onNext() {
            // 获取当页的属性
            let fieldNameArray = [];
            for (let fieldName in this.diaoCha) {
                if (Object.prototype.hasOwnProperty.call(this.diaoCha, fieldName)) {
                    if (this.diaoCha[fieldName].pageIndex === this.page.current) {
                        fieldNameArray.push(fieldName);
                    } else if (this.diaoCha[fieldName].pageIndex > this.page.current) {
                        break;
                    }
                }
            }

            // 对当页属性进行校验
            this.$refs.diaoChaForm.validate(fieldNameArray).then(() => {
                let current = this.page.current;
                if (current < this.page.total - 1) {
                    this.page.current++;
                } else {
                    this.$refs.diaoChaForm.submit();
                }
            }).catch(res => {
                Notify({type: 'danger', message: res[0].message});
            });
        },

        // 表单提交
        onSubmit(values) {
            axios({
                method: "post",
                url: GLOBAL.config.server + "/xnk/diaoCha/save",
                data: values,
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((res) => {
                if (res.data.code === GLOBAL.config.success) {
                    this.$router.push({path: '/xnk/diaoCha/h5/success'});
                } else {
                    Toast("当前网络不佳，请稍候再试");
                }
            }).catch(() => {
                Toast("当前网络不佳，请稍候再试");
            });
        },
    },
    watch: {
        // 监听全马、半马次数变化，进行成绩验证规则的调整
        'diaoCha.quanMaCiShu.value': function (newVal) {
            if (newVal !== null && newVal.length === 0) {
                newVal = 0;
            }
            this.diaoCha.quanMaChengJi.rules[0].required = parseInt(newVal) > 0;
        },
        'diaoCha.banMaCiShu.value': function (newVal) {
            if (newVal !== null && newVal.length === 0) {
                newVal = 0;
            }
            this.diaoCha.banMaChengJi.rules[0].required = parseInt(newVal) > 0;
        },
    }
}
