import {
    Button,
    Card,
    Layout,
    PageHeader,
    Table,
    ConfigProvider
} from 'ant-design-vue'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import axios from 'axios';
import GLOBAL from '@/common/global'

export default {
    name: "DiaoChaList",
    components: {
        ALayout: Layout,
        ALayoutHeader: Layout.Header,
        ALayoutContent: Layout.Content,
        ALayoutFooter: Layout.Footer,
        APageHeader: PageHeader,
        ACard: Card,
        ATable: Table,
        AButton: Button,
        AConfigProvider: ConfigProvider
    },
    data() {
        return {
            contentHeight:0,
            locale: zhCN,
            tabs: {
                active: "0",
                items: []
            },
            columns: [
                {
                    title: '#',
                    dataIndex: 'id',
                    key: 'id',
                    align: "center",
                    width: '5%',
                    scopedSlots: {customRender: 'tIndex'}
                },
                {
                    title: '姓名',
                    dataIndex: 'xingMing',
                    key: 'xingMing',
                    align: "center",
                    width: '10%'
                },
                {
                    title: '性别',
                    dataIndex: 'xingBie',
                    key: 'xingBie',
                    align: "center",
                    width: '10%'
                },
                {
                    title: '出生年月',
                    dataIndex: 'chuShengNianYue',
                    key: 'chuShengNianYue',
                    align: "center",
                    width: '10%'
                },
                {
                    title: '身高（厘米）',
                    dataIndex: 'shenGao',
                    key: 'shenGao',
                    align: "center",
                    width: '10%'
                },
                {
                    title: '体重（公斤）',
                    dataIndex: 'tiZhong',
                    key: 'tiZhong',
                    align: "center",
                    width: '10%'
                },
                {
                    title: '电话号码',
                    dataIndex: 'dianHua',
                    key: 'dianHua',
                    align: "center",
                    width: '15%'
                },
                {
                    dataIndex: 'updateTime',
                    key: 'updateTime',
                    align: "center",
                    width: '15%',
                    scopedSlots: {title: 'timeTitle'},
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    key: 'action',
                    align: "center",
                    width: '15%',
                    scopedSlots: {customRender: 'action'}
                }
            ],
            timeTitle: "提交时间"
        }
    },
    mounted() {
        // 初始化页面
        this.initPage();
        // 动态设置内容高度 让footer始终居底   header固定高度64+footer固定高度69的高度是133
        this.contentHeight = document.documentElement.clientHeight - 133;
    },
    activated() {
        // 从缓存获取，是否需要刷新页面
        let reload = sessionStorage.getItem("reload-list");
        if (reload) {
            // 如果需要刷新，先清除缓存，再初始化页面
            sessionStorage.removeItem("reload-list");
            this.initPage();
        }
    },
    methods: {
        // 初始化页面
        initPage() {
            // 初始化tab和table参数
            this.tabs.items = [{
                key: '0',
                tab: '未处理',
                init: true,
                loading: false,
                pagination: {current: 1},
                data: []
            }, {
                key: '1',
                tab: '已纳入',
                init: true,
                loading: false,
                pagination: {current: 1},
                data: []
            }, {
                key: '2',
                tab: '已排除',
                init: true,
                loading: false,
                pagination: {current: 1},
                data: []
            }];

            // 加载首页数据
            this.fetch({
                pageNo: 0,
                status: this.tabs.active
            });
        },

        // Tab切换事件
        onTabChange(key) {
            // 更新active
            this.tabs.active = key;
            if ("0" === key) {
                this.timeTitle = "提交时间";
            } else {
                this.timeTitle = "处理时间";
            }

            // 如果当前页面未加载表格，则进行加载
            let index = parseInt(key);
            if (this.tabs.items[index].init) {
                this.fetch({
                    pageNo: 0,
                    status: key
                });
            }
        },
        //下载全表Excel
        onClickDownload() {
            // 弹出确认模态窗口
            this.$confirm({
                title: '确认导出吗?',
                content: '如果确认，将以Excel形式导出全部调查数据',
                okText: '确认',
                cancelText: '取消',
                onOk() {
                    window.location.href = GLOBAL.config.server + '/xnk/diaoCha/exportList';
                }
            });
        },
        // 表格变化事件
        handleTableChange(pagination) {
            // 获取当前选中的tab页状态及索引
            let active = this.tabs.active;
            let index = parseInt(active);

            // 更新当前tab表格的分页情况
            const pager = {...this.tabs.items[index].pagination};
            pager.current = pagination.current;
            this.tabs.items[index].pagination = pager;

            // 获取分页数据
            this.fetch({
                pageNo: pagination.current,
                status: active
            });
        },

        /**
         * 获取分页数据
         * @param params 查询参数。pageNo：页码，status:调查表状态
         */
        fetch(params = {}) {
            // 获取当前tab索引
            let index = parseInt(this.tabs.active);

            // 更新当前表格相关参数
            this.tabs.items[index].init = false;
            this.tabs.items[index].loading = true;

            // 从服务器查询数据
            axios({
                method: "get",
                url: GLOBAL.config.server + "/xnk/diaoCha/page",
                params: {...params}
            }).then((res) => {
                if (res.data.code === GLOBAL.config.success) {
                    // 获取分页数据
                    let result = res.data.data;

                    // 更新表格参数
                    const pagination = {...this.tabs.items[index].pagination};
                    pagination.total = result.total;
                    this.tabs.items[index].loading = false;
                    this.tabs.items[index].data = result.data;
                    this.tabs.items[index].pagination = pagination;
                } else {
                    this.$message.warning('当前网络不佳，请稍候再试');
                }
            }).catch(() => {
                this.$message.warning('当前网络不佳，请稍候再试');
            });
        },

        /**
         * 点击查看详情事件
         * @param id 调查表id
         */
        onClickToInfo(id) {
            // 前往查看详情页面
            this.$router.push({
                path: '/xnk/diaoCha/info',
                query: {
                    id: id
                }
            });
        }
    }
}
